<template>
    <div v-if="loading" class="verify-payment">
        <h1>Verifying Payment</h1>
        <div class="loader"></div>
    </div>
    <div v-else-if="success" class="verify-payment">
        <h1>Payment Successful</h1>
        <p>Redirecting</p>
    </div>
    <div v-else class="verify-payment">
        <h1>
            Payment Unsuccessful
        </h1>
        <p>Redirecting</p>
    </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
    name: 'VerifyPayment',
    beforeRouteEnter(to, from, next) {
        if (!to.query?.eventID) {
            console.log('No eventId ')
            console.log('Redirecting to')
            next(from)
        } else {
            next()
        }
    },
    data() {
        return {
            loading: true,
            success: false
        }
    },
    async created() {
        try {
            const eventID = this.$route.query.eventID
            await this.$apollo.mutate({
                mutation: gql`
                    mutation EnsurePayment($eventID: ID!) {
                        ensurePayment(eventID: $eventID) {
                            id
                            paymentStatus
                        }
                    }
                `,
                variables: {
                    eventID
                }
            })
            this.success = true
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false
            const self = this
            setTimeout(() => self.$router.replace('/events/requests/outgoing'), 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
.verify-payment {
    height: 100vh;
    display: grid;
    place-content: center;
    justify-items: center;
    background-color: $primary;
    color: $beige;
}

.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: $primary;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
